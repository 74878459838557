<template>
  <validation-observer v-slot="{handleSubmit}">
    <b-card header="Add Donation">
      <b-form @submit.prevent="handleSubmit(addDonation)">
        <donation-details :donation="donation" />
        <b-button
          type="submit"
          variant="primary"
        >
          Add
        </b-button>
        <back />
      </b-form>
    </b-card>
  </validation-observer>
</template>
<script>
import DonationDetails from '@/common/components/Transactions/DonationDetails.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'AddDonation',
  components: { DonationDetails, Back },
  data() {
    return {
      donation: {},
    }
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()

    return {
      successfulOperationAlert, showErrors,
    }
  },
  methods: {
    addDonation() {
      this.$payment.post('internalops/addDonation', {
        user_id: this.$route.params.user_id,
        entity_id: this.$store.getters['mainEntity/getEntityId'],
        ...this.donation,
      }).then(() => {
        this.successfulOperationAlert('Donation is added successfully')
        this.$router.push({ name: 'donations-list' })
      }).catch(({ response }) => {
        this.showErrors(response.data.errors)
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
