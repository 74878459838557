<template>
  <div>
    <b-form-row>
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Donation Category"
          rules="required"
        >
          <b-form-group
            label="Donation Category"
            label-for="donation-category"
          >
            <v-select
              id="donation-category"
              v-model="donation.donation_category_id"
              label="category_name"
              :reduce="category => category.id"
              :options="donationCategoriesOptions"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Donation Method"
          rules="required"
        >
          <b-form-group
            label="Donation Method"
            label-for="donation_method"
          >
            <b-form-select
              id="donation_method"
              v-model="donation.donation_method_id"
              :options="donationMethodOptions"
              value-field="id"
              text-field="name"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Total Amount"
          rules="required|min:2|max:20"
        >
          <b-form-group
            label="Total Amount"
            label-for="total_amount"
          >
            <b-form-input
              id="total_amount"
              v-model="donation.total_amount"
              type="number"
              placeholder="Total Amount"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Net Amount"
          :rules="`required|min:2|max:20|max_value:${donation.total_amount}`"
        >
          <b-form-group
            label="Net Amount"
            label-for="net_amount"
          >
            <b-form-input
              id="net_amount"
              v-model="donation.net_amount"
              type="number"
              placeholder="Net Amount"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <validation-provider
          #default="validationContext"
          name="Date"
          rules="required"
        >
          <b-form-group
            label="Date"
            label-for="date"
          >
            <b-form-datepicker
              id="date"
              v-model="donation.date"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <validation-provider
          #default="validationContext"
          name="Notes"
          rules="max:20000"
        >
          <b-form-group
            label="Notes"
            label-for="notes"
          >
            <b-textarea
              id="notes"
              v-model="donation.notes"
              placeholder="Notes"
              no-resize
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  name: 'DonationDetails',
  components: {
    vSelect,
  },
  props: {
    donation: { type: Object, default: () => {} },
  },
  data() {
    return {
      donationCategoriesOptions: [],
      donationMethodOptions: [],
    }
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
    }
  },
  created() {
    this.getDonationCategories()
    this.getDonationMethods()
  },
  methods: {
    getDonationCategories() {
      this.$entities.get(`internalops/entity-donations-category/${this.$store.getters['mainEntity/getEntityId']}`).then(({ data }) => {
        this.donationCategoriesOptions = data.data
      })
    },
    getDonationMethods() {
      this.$payment.get('internalops/getDonationMethods').then(({ data }) => {
        this.donationMethodOptions = data.data
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
